import { FormLogin } from "../FormLogin";
import selo from "@/assets/images/selo.webp";
import styles from "./styles.module.css";

export function Desenrola() {
  return (
    <section id="inicio" className={styles.wrapper}>
      <div className={styles.inner_wrapper}>
        <div className={styles.banner_wrapper}>
          <div className={styles.image_wrapper}>
            <img src={selo} alt="Selo" />
          </div>
          <div className={styles.box_wrapper}>
            <header className={styles.box}>
              <p>ofertas imperdíveis</p>
              <p>com até</p>
              <p>95%</p>
              <p>desconto</p>
            </header>
            <p>*Oferta disponível conforme a condição do credor</p>
          </div>
        </div>

        <FormLogin />
      </div>
    </section>
  );
}
