import { PropsWithChildren } from "react";
import * as CheckboxRadix from "@radix-ui/react-checkbox";
import { Check } from "@havan/paladinos-react-icons";
import { Indicator, Root, Wrapper } from "./styles";

type Props = CheckboxRadix.CheckboxProps & PropsWithChildren;

export function Checkbox({ children, ...rest }: Props) {
  return (
    <Wrapper>
      <Root {...rest}>
        <Indicator>
          <Check />
        </Indicator>
      </Root>
      {children}
    </Wrapper>
  );
}
