import { Button } from "../../../components/Button";
import { styled } from "../../../styles";

export const Container = styled("div", {
  lineHeight: "22px",
  color: "$neutralDarkest",
});

export const ValueDate = styled("p", {
  fontWeight: "$bold",
  color: "$neutralDarkest",
});

export const Buttons = styled("div", {
  display: "flex",
  gap: 24,

  button: {
    width: "100%",
  },

  "@max_width_480": {
    flexDirection: "column",
  },
});

export const ButtonPayment = styled("button", {
  height: 46,
  borderRadius: 8,
  padding: "12px $8",
  fontFamily: "$default",
  fontWeight: "$bold",
  lineHeight: "22px",
  border: "1.5px solid $secondaryDark",
  boxSizing: "border-box",

  "&:hover": {
    cursor: "pointer",
  },

  variants: {
    variant: {
      solid: {
        backgroundColor: "$secondaryDark",
        color: "$neutralLighter",
      },
      outline: {
        backgroundColor: "transparent",
        color: "$secondaryDark",
      },
    },
  },
  defaultVariants: {
    variant: "solid",
  },
});

export const ModalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 32,

  "@max_width_480": {
    padding: "0 $4",
    gap: 24,
  },
});

export const Instruction = styled("div", {
  marginTop: 32,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  gap: 32,
  maxWidth: 275,
});

export const InstructionText = styled("p", {
  fontWeight: 700,
  lineHeight: "22px",
  color: "$primaryDarkest",
});

export const Action = styled(Button, {
  width: "100%",
  maxWidth: 374,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
});

export const Payment = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: 16,
  width: "100%",
  maxWidth: 374,
  backgroundColor: "$primaryLighter",
  border: "1px solid $primaryLight",
  borderRadius: 8,
  boxSizing: "border-box",
});

export const PaymentInfoLine = styled("div", {
  display: "flex",
  fontSize: "$xxs",
  lineHeight: "19px",
  justifyContent: "space-between",
});

export const PaymentInfoLabel = styled("p", {
  display: "flex",
  alignItems: "center",
  gap: 4,
  color: "$neutralDark",

  svg: {
    color: "$primaryMedium",
  },
});

export const PaymentInfoValue = styled("p", {
  fontWeight: "$bold",
  color: "$neutralDarkest",
});

export const DescriptionLine = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});
