import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Invoice } from "@havan/paladinos-react-icons";
import { Box } from "@/components/Box";
import { Step } from "@/components/Step";
import { Terms } from "@/components/Terms";
import { Renegociacao } from "@/services/renegociacao";
import { PAGE_TITLE } from "@/utils/constants";
import { SimulationStep } from "@/enums/SimulationStep";
import { useSimulation } from "@/providers/Simulation/hook";
import { Loading } from "@/components/Loading";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { PaymentState } from "../pagamento/types";
import { SummaryAgreement } from "../components/SummaryAgreement";
import { Container } from "../bestOffer/styles";
import { LoadingButton } from "../paymentInInstallment/summary/styles";

export function PaymentInCash() {
  const { bestOffer, setSimulationStep } = useSimulation();
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isClosingDeal, setIsClosingDeal] = useState(false);

  const navigate = useNavigate();
  const alert = useAlert();

  useLayoutEffect(() => {
    document
      .getElementById("fechar-acordo-a-vista")!
      .addEventListener("click", function () {
        (window as any).gtag("event", "fechar-acordo-a-vista", {
          value: bestOffer?.valorDivida,
          parcelas: 1,
          valorEntrada: bestOffer?.valorDivida,
          valorParcela: bestOffer?.valorDivida,
          currency: "BRL",
          transaction_id: bestOffer?.parcelamento[0].hash,
        });
      });
  });

  function efetivarAcordo() {
    if (!!bestOffer) {
      setIsClosingDeal(true);
      Renegociacao.efetivarAcordo({
        hash: bestOffer.parcelamento[0].hash,
      })
        .then((response) => {
          const { data } = response;
          const payment = {
            total: data.result.valorParcela,
            expiresAt: data.result.dataPrimeiroVencimento,
          } as PaymentState;
          navigate("/pagamento", { state: payment });
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response?.data.errors) {
            error.response.data.errors.forEach((errorMessage) => {
              alert.alert("Atenção", errorMessage);
            });
          }
        })
        .finally(() => setIsClosingDeal(false));
    }
  }

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Resumo do pagamento à vista`;
  }, []);

  return (
    <Container>
      <Step
        handleStepBack={() => setSimulationStep(SimulationStep.BestOffer)}
        title="Resumo da Negociação"
        current={4}
        max={4}
      />
      <Box.Root>
        <Box.Header>
          <Invoice height={32} width={32} />
          Acordo
        </Box.Header>
        <SummaryAgreement
          parcela={bestOffer?.parcelamento[0]}
          valorDivida={bestOffer?.valorDivida}
          expiresAt={bestOffer?.parcelamento[0].dataVencimento}
        />
      </Box.Root>
      <Terms
        isTermsChecked={isTermsChecked}
        setTermsChecked={setIsTermsChecked}
      />
      <LoadingButton
        id="fechar-acordo-a-vista"
        onClick={() => efetivarAcordo()}
        disabled={!isTermsChecked || isClosingDeal}
      >
        {isClosingDeal ? (
          <>
            <Loading />
            Fechando acordo...
          </>
        ) : (
          "Confirmar"
        )}
      </LoadingButton>
    </Container>
  );
}
