import { useEffect, useState } from "react";
import { parse, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { CreditCard, Lock } from "@havan/paladinos-react-icons";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import people1 from "@/assets/images/Mulher-Negociar-Divida-Havan.png";
import people2 from "@/assets/images/Mulher2-Negociar-Divida-Havan.png";
import people3 from "@/assets/images/Moca-Negociar-Divida-Havan.png";
import people4 from "@/assets/images/Mulher3-Negociar-Divida-Havan.png";
import { useAuth } from "@/auth/hook";
import { Step } from "@/components/Step";
import { Auth } from "@/services/auth";
import {
  extractDDDPhoneNumber,
  formatBirthdate,
  formatCpf,
  formatPhoneNumber,
  isBirthdateValid,
  isCpfValid,
  phoneNumberIsValid,
} from "@/utils/formatters";
import { SituacaoCadastro } from "@/enums/SituacaoCadastro";
import { PAGE_TITLE } from "@/utils/constants";
import {
  BoxForm,
  BoxFormHeader,
  BoxFormHeading,
  Container,
  Content,
  DealDebtLabel,
  Description,
  Form,
  Heading,
  HeadingContainer,
  OfferLabel,
  PeopleCard,
  PeopleCards,
} from "./styles";

export function RenegociarHome() {
  const { auth, attempt } = useAuth();
  const navigate = useNavigate();
  const [cpf, setCpf] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const isPhoneValid = phoneNumberIsValid(phone);
  const isCpfInputSuccess = cpf.length > 0 && isCpfValid(cpf);
  const isCpfInputError = cpf.length > 0 && !isCpfInputSuccess;
  const isInputBirthdateValid = isBirthdateValid(birthdate);

  function submit() {
    const { ddd, numeroCelular } = extractDDDPhoneNumber(phone);

    const isoBirthdate = parse(
      birthdate,
      "dd/MM/yyyy",
      new Date()
    ).toISOString();

    if (!auth.user) {
      attempt({ cpf, birthdate: isoBirthdate });
      return;
    }

    Auth.contato({ email, ddd, numeroCelular }).then((response) => {
      attempt({ cpf: auth.user.cpf, birthdate: auth.user.birthdate });
    });
  }

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Dados pessoais`;

    if (auth.user) {
      if (auth.user.statusCadastro === SituacaoCadastro.Completo) {
        return navigate("/negociar/acordo");
      }

      setCpf(auth.user.cpf ?? "");
      setBirthdate(parseISO(auth.user.birthdate).toLocaleDateString() ?? "");
    }
  }, []);

  return (
    <Container>
      <Content>
        <HeadingContainer>
          <OfferLabel>
            <Lock /> Garantia de Segurança Havan
          </OfferLabel>
          <Heading>Negocie sua dívida do Cartão Havan sem sair de casa</Heading>
          <Description>É rápido, seguro e você faz tudo online.</Description>
          <Description>
            Informe seus dados e encontre a melhor opção de negociação
            disponível pra você!
          </Description>
          <PeopleCards>
            <PeopleCard variant="terciary">
              <img
                src={people4}
                alt="Moça vestida de branco sorrindo e de braços cruzados"
              />
            </PeopleCard>
            <PeopleCard variant="primary">
              <img
                src={people3}
                alt="Moça vestida de branco segurando Cartão Havan e celular"
              />
            </PeopleCard>
            <PeopleCard variant="secondary">
              <img
                src={people1}
                alt="Mulher vestida de amarelo segurando Cartão Havan e celular"
              />
            </PeopleCard>
            <PeopleCard variant="terciary">
              <img
                src={people2}
                alt="Família composta por uma criança, uma mulher e uma senhora se abraçando"
              />
            </PeopleCard>
          </PeopleCards>
        </HeadingContainer>
        <BoxForm>
          <Step title="Dados pessoais" current={1} max={4} />
          <BoxFormHeader>
            <DealDebtLabel>
              <CreditCard />
              Negociar dívida do seu Cartão Havan
            </DealDebtLabel>
            <BoxFormHeading>Informe seus dados pessoais</BoxFormHeading>
          </BoxFormHeader>
          <Form action="">
            <Input
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              value={cpf}
              onChange={(event) => setCpf(formatCpf(event.target.value))}
              isError={isCpfInputError}
              isSuccess={isCpfInputSuccess}
              maxLength={14}
            />
            <Input
              name="birthdate"
              label="Data de nascimento"
              placeholder="00/00/0000"
              value={birthdate}
              onChange={(event) =>
                setBirthdate(formatBirthdate(event.target.value))
              }
              isSuccess={isInputBirthdateValid && birthdate.length > 0}
              isError={!isInputBirthdateValid}
              maxLength={10}
            />
            {auth.user?.statusCadastro === SituacaoCadastro.Incompleto && (
              <>
                <Input
                  name="email"
                  label="E-mail"
                  placeholder="e-mail@exemplo.com.br"
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Input
                  name="phone"
                  type="text"
                  label="Celular"
                  maxLength={14}
                  placeholder="(XX) XXXXX-XXXX"
                  value={phone}
                  isSuccess={isPhoneValid}
                  isError={!isPhoneValid && phone.length > 0}
                  onChange={(event) =>
                    setPhone(formatPhoneNumber(event.target.value))
                  }
                />
              </>
            )}
          </Form>
          <Button
            id={
              auth.user?.statusCadastro === SituacaoCadastro.Incompleto
                ? "cadastro"
                : "login"
            }
            onClick={submit}
          >
            Ver o melhor acordo
          </Button>
        </BoxForm>
      </Content>
    </Container>
  );
}
