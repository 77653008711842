import { InputHTMLAttributes } from "react";
import { Check, Close, Info } from "@havan/paladinos-react-icons";
import {
  Container,
  Content,
  Currency,
  HelperText,
  Label,
  StyledInput,
} from "./styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isSuccess?: boolean;
  isError?: boolean;
  isMoney?: boolean;
  helperTextType?: "default" | "success" | "error";
  helperText?: string;
}

export function Input({
  label,
  isSuccess,
  isError,
  isMoney,
  helperText,
  helperTextType = "default",
  ...rest
}: Props) {
  return (
    <Container>
      <Label htmlFor={label}>{label}</Label>
      <Content isError={isError} isSuccess={isSuccess}>
        {isMoney && <Currency>R$</Currency>}
        {isSuccess && !isError && <Check />}
        {isError && !isSuccess && <Close />}
        <StyledInput {...rest} />
      </Content>
      {helperText && (
        <HelperText>
          <Info />
          {helperText}
        </HelperText>
      )}
    </Container>
  );
}

Input.displayName = "Input";
