import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import {
  CalendarPositive,
  Close,
  Coin,
  Copy,
  Download,
} from "@havan/paladinos-react-icons";
import billMobile from "@/assets/images/billMobile.png";
import { Actions, LinkBoleto } from "./styles";
import { Pagamento } from "@/services/pagamento";
import { Boleto } from "@/services/pagamento/types";
import { Loading } from "@/components/Loading";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import {
  ModalContent,
  ModalIconClose,
  ModalOverlay,
  ModalTitle,
} from "@/components/Modal/styles";
import {
  Action,
  ButtonPayment,
  Instruction,
  InstructionText,
  ModalBody,
  Payment,
  PaymentInfoLabel,
  PaymentInfoLine,
  PaymentInfoValue,
} from "../styles";

export function ModalBoleto() {
  const [boleto, setBoleto] = useState<Boleto>();
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const alert = useAlert();

  useEffect(() => {}, []);

  function fetchBoleto() {
    setIsFetching(true);
    Pagamento.boleto()
      .then((response) => setBoleto(response.data.result.boletos[0]))
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response?.data.errors) {
          const { errors } = error.response.data;

          errors.forEach((message) => {
            alert.alert("Boleto", message);
          });
        }

        setIsOpen(false);
      })
      .finally(() => setIsFetching(false));
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <ButtonPayment
          id="abre-modal-boleto"
          variant="outline"
          onClick={() => fetchBoleto()}
        >
          Boleto
        </ButtonPayment>
      </Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Pagar com Boleto</ModalTitle>
            <ModalBody>
              {isOpen && !!boleto && !isFetching ? (
                <>
                  <Instruction>
                    <img src={billMobile} alt="" />
                    <InstructionText>
                      Copie e cole o código de barras do boleto no seu
                      aplicativo de Banco
                    </InstructionText>
                  </Instruction>
                  <Actions>
                    <LinkBoleto
                      id="download-pdf-boleto"
                      to={`${boleto?.urlBoleto}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Download /> Baixar Boleto em PDF
                    </LinkBoleto>
                    <Action
                      id="copia-codigo-boleto"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          boleto?.linhaDigitavelBoleto ?? ""
                        )
                      }
                    >
                      <Copy /> Copiar código do Boleto
                    </Action>
                  </Actions>
                  <Payment>
                    <PaymentInfoLine>
                      <PaymentInfoLabel>
                        <Coin />
                        Valor
                      </PaymentInfoLabel>
                      <PaymentInfoValue>{boleto?.valorBoleto}</PaymentInfoValue>
                    </PaymentInfoLine>
                    <PaymentInfoLine>
                      <PaymentInfoLabel>
                        <CalendarPositive />
                        Vencimento
                      </PaymentInfoLabel>
                      <PaymentInfoValue>
                        {boleto?.dataVencimentoBoleto}
                      </PaymentInfoValue>
                    </PaymentInfoLine>
                  </Payment>
                </>
              ) : (
                <Loading />
              )}
            </ModalBody>
            <Dialog.Close asChild>
              <ModalIconClose aria-label="Close">
                <Close />
              </ModalIconClose>
            </Dialog.Close>
          </ModalContent>
        </ModalOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
