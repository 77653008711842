import { Box } from "../../../../components/Box";
import { Button } from "../../../../components/Button";
import { styled } from "../../../../styles";

export const BoxRoot = styled(Box.Root, {
  position: "relative",
  marginTop: 16,
  paddingTop: 32,
  textAlign: "start",
});

export const Detail = styled("div", {
  height: 16,
  backgroundColor: "$primaryMedium",
  position: "absolute",
  top: 0,
  right: 0,
  borderRadius: "14.5px 14.5px 0 0",
  width: "100%",
});

export const EntryContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

export const Muted = styled("p", {
  fontWeight: "$regular",
  fontSize: "$xxxs",
  lineHeight: "16px",
  color: "$neutralDark",
});

export const DescriptionLine = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

export const LabelDebt = styled("p", {
  lineHeight: "22px",
  color: "$neutralDarkest",
});

export const ValueDebt = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDark",
});

export const LabelDiscount = styled("span", {
  padding: "2px 8px",
  height: 23,
  backgroundColor: "$secondaryMedium",
  borderRadius: 24,
  fontWeight: "$bold",
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$neutralLighter",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ValueDiscount = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$secondaryMedium",
});

export const LabelPayment = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDarkest",
  display: "flex",
  gap: 8,
  alignItems: "center",

  svg: {
    color: "$primaryMedium",
  },
});

export const ValuePayment = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDarkest",
});

export const LoadingButton = styled(Button, {
  marginTop: 32,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  div: {
    margin: 0,
    size: 32,
    svg: {
      display: "block",
      size: 32,
    },
  },
});
