import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LogoLettering, Menu } from "@havan/paladinos-react-icons";
import styles from "./navbar.module.css";

const HASH_BENEFITS = "#beneficios";
const HASH_DOUBTS = "#duvidas";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  function handleScroll() {
    const { hash } = window.location;

    const scrollTo = document.getElementById(hash?.replace("#", ""));

    if (!scrollTo) return;

    window.scrollTo({
      top: scrollTo.offsetTop - 100,
      behavior: "smooth",
    });
  }

  function onClickMenuItem(hash?: string) {
    setIsMenuOpen(false);

    if (hash) return navigate({ pathname: "/", hash });

    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function navbarHighlight(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      const navButton = document.getElementById(
        `button-${entry.target.getAttribute("id")}`
      );

      if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
        navButton?.classList?.add("active");
      } else {
        navButton?.classList?.remove("active");
      }
    });
  }

  useLayoutEffect(() => handleScroll(), [window.location?.hash]);

  useEffect(() => {
    const observer = new IntersectionObserver(navbarHighlight, {
      threshold: 1,
    });

    const sectionInit = document.getElementById("inicio");
    const sectionBenefits = document.getElementById("beneficios");
    const sectionDoubts = document.getElementById("duvidas");

    if (sectionInit && sectionBenefits && sectionDoubts) {
      observer.observe(sectionInit);
      observer.observe(sectionBenefits);
      observer.observe(sectionDoubts);
    }
  }, []);

  return (
    <nav className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.logo_wrapper}>
          <div className={styles.logos}>
            <LogoLettering height={24} width="auto" />
          </div>
          <button>
            <label htmlFor="toggle">
              <Menu />
            </label>
          </button>
        </div>
        <input
          id="toggle"
          type="checkbox"
          checked={isMenuOpen}
          onChange={(event) => setIsMenuOpen(event.target.checked)}
          hidden
        />
        <div className={styles.links_wrapper}>
          <button id="button-inicio" onClick={() => onClickMenuItem()}>
            Início
          </button>
          <button
            id="button-beneficios"
            onClick={() => onClickMenuItem(HASH_BENEFITS)}
          >
            Benefícios
          </button>
          <button
            id="button-duvidas"
            onClick={() => onClickMenuItem(HASH_DOUBTS)}
          >
            Dúvidas
          </button>
          <Link id="renegociar-divida" to={{ pathname: "/negociar" }}>
            Negociar dívida
          </Link>
        </div>
      </div>
    </nav>
  );
}
