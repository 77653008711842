import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Coin, Invoice } from "@havan/paladinos-react-icons";
import { AxiosError } from "axios";
import { Box } from "@/components/Box";
import { Step } from "@/components/Step";
import { Terms } from "@/components/Terms";
import { formatMoney } from "@/utils/formatters";
import { Renegociacao } from "@/services/renegociacao";
import { PAGE_TITLE } from "@/utils/constants";
import { useSimulation } from "@/providers/Simulation/hook";
import { SimulationStep } from "@/enums/SimulationStep";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { Loading } from "@/components/Loading";
import { Info } from "@/components/Info";
import { PaymentState } from "../../pagamento/types";
import { Line } from "../../components/SummaryAgreement/styles";
import { Container } from "../../bestOffer/styles";
import {
  BoxRoot,
  DescriptionLine,
  Detail,
  LabelDebt,
  LabelDiscount,
  LabelPayment,
  LoadingButton,
  Muted,
  ValueDebt,
  ValueDiscount,
  ValuePayment,
} from "./styles";

export function PaymentInInstallmentsSummary() {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isClosingDeal, setIsClosingDeal] = useState(false);
  const { bestOffer, installments, installmentOptions, setSimulationStep } =
    useSimulation();
  const navigate = useNavigate();
  const alert = useAlert();

  const parcela = installmentOptions?.parcelamento.find(
    (parcela) => parcela.parcelas === installments
  );

  const discount =
    parcela?.valorTotal && !!bestOffer
      ? Math.abs(
          Math.ceil((parcela.valorDiferenca / bestOffer.valorDivida) * 100)
        )
      : undefined;

  const showDiscount = !!parcela ? parcela.valorDiferenca < 0 : false;

  useLayoutEffect(() => {
    if (parcela) {
      document
        .getElementById("fechar-acordo-parcelado")!
        .addEventListener("click", function () {
          (window as any).gtag("event", "fechar-acordo-parcelado", {
            value: bestOffer?.valorDivida,
            parcelas: parcela.parcelas,
            valorEntrada: parcela.valorEntrada,
            valorParcela: parcela.valorParcela,
            currency: "BRL",
            transaction_id: parcela.hash,
          });
        });
    }
  });

  function efetivarAcordo() {
    if (parcela) {
      const payment = {
        total: parcela.valorEntrada,
        expiresAt: parcela.dataVencimento,
      } as PaymentState;
      setIsClosingDeal(true);
      Renegociacao.efetivarAcordo({ hash: parcela.hash })
        .then((response) => {
          navigate("/pagamento", { state: payment });
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response?.data.errors) {
            error.response.data.errors.forEach((errorMessage) => {
              alert.alert("Atenção", errorMessage);
            });
          }
        })
        .finally(() => setIsClosingDeal(false));
    }
  }

  function stepBack() {
    setSimulationStep(SimulationStep.PaymentInInstallments);
  }

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Resumo do parcelamento`;
  });

  return (
    <Container>
      <Step
        handleStepBack={() => stepBack()}
        title="Resumo da Negociação"
        current={4}
        max={4}
      />
      <Box.Root>
        <Box.Header>
          <Invoice />
          Acordo
        </Box.Header>
        {showDiscount && (
          <>
            <DescriptionLine>
              <LabelDebt>Valor da dívida</LabelDebt>
              <ValueDebt>
                {!!installmentOptions?.valorDivida &&
                  formatMoney(installmentOptions.valorDivida)}
              </ValueDebt>
            </DescriptionLine>
            <DescriptionLine>
              <LabelDiscount>
                Desconto {Number.isInteger(discount) && discount}%
              </LabelDiscount>
              <ValueDiscount>
                {!!parcela && formatMoney(Math.abs(parcela.valorDiferenca))}
              </ValueDiscount>
            </DescriptionLine>
          </>
        )}
        <DescriptionLine>
          <LabelPayment>
            <Coin /> Valor total
          </LabelPayment>
          <ValuePayment>
            {!!parcela && formatMoney(parcela.valorTotal)}
          </ValuePayment>
        </DescriptionLine>
      </Box.Root>
      <BoxRoot>
        <Detail />
        <Box.Header>
          <Invoice /> Pagamento
        </Box.Header>
        <Line>
          <div>
            <LabelDebt>Valor de entrada</LabelDebt>
            <Muted>
              Vencimento dia{" "}
              {!!parcela &&
                new Date(parcela.dataVencimento).toLocaleDateString()}
            </Muted>
          </div>
          <ValuePayment>
            {!!parcela && formatMoney(parcela.valorEntrada)}
          </ValuePayment>
        </Line>
        <Line>
          <LabelDebt>Parcelas</LabelDebt>
          {!!parcela && (
            <ValuePayment>
              {parcela.parcelas - 1}x de{" "}
              {formatMoney(parcela?.valorParcela ?? 0)}
            </ValuePayment>
          )}
        </Line>
        <Info>
          As parcelas serão adicionadas a fatura do seu Cartão Havan, o
          vencimento será o mesmo da fatura
        </Info>
      </BoxRoot>
      <Terms
        isTermsChecked={isTermsChecked}
        setTermsChecked={setIsTermsChecked}
      />
      <LoadingButton
        id="fechar-acordo-parcelado"
        onClick={() => efetivarAcordo()}
        disabled={!isTermsChecked || isClosingDeal}
      >
        {isClosingDeal ? (
          <>
            <Loading />
            Fechando acordo...
          </>
        ) : (
          "Confirmar"
        )}
      </LoadingButton>
    </Container>
  );
}
