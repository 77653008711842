import { styled } from "../../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 24,

  hr: {
    border: "1px solid $primaryLight",
    borderBottomWidth: 0,
  },
});

export const Line = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const LabelDebt = styled("p", {
  lineHeight: "22px",
  color: "$neutralDarkest",
});

export const ValueDebt = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDark",
});

export const LabelDiscount = styled("span", {
  padding: "2px 8px",
  height: 23,
  backgroundColor: "$secondaryMedium",
  borderRadius: 24,
  fontWeight: "$bold",
  fontSize: "$xxs",
  lineHeight: "19px",
  color: "$neutralLighter",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ValueDiscount = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$secondaryMedium",
});

export const LabelPayment = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDarkest",
  display: "flex",
  gap: 8,
  alignItems: "center",

  svg: {
    color: "$primaryMedium",
  },
});

export const ValuePayment = styled("p", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDarkest",
});
