import { SimulationStep } from "@/enums/SimulationStep";
import { useSimulation } from "@/providers/Simulation/hook";
import { PaymentInCash } from "./paymentInCash";
import { BestOffer } from "./bestOffer";
import { PaymentInInstallments } from "./paymentInInstallment/downPayment";
import { PaymentInInstallmentsSummary } from "./paymentInInstallment/summary";

export function Renegociar() {
  const { simulationStep } = useSimulation();

  if (simulationStep === SimulationStep.BestOffer) {
    return <BestOffer />;
  }

  if (simulationStep === SimulationStep.PaymentInCash) {
    return <PaymentInCash />;
  }

  if (simulationStep === SimulationStep.PaymentInInstallments) {
    return <PaymentInInstallments />;
  }

  if (simulationStep === SimulationStep.PaymentInInstallmentsSummary) {
    return <PaymentInInstallmentsSummary />;
  }

  return null;
}
