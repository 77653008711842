import { Outlet, useLocation } from "react-router-dom";
import { Warning } from "@havan/paladinos-react-icons";
import { Header } from "../../components/Header";
import { Container, Heading, Text } from "./styles";

export function LayoutPagamento() {
  const { state } = useLocation();
  return (
    <>
      <Header />
      <Container>
        {!state.payment?.notPaid ? (
          <>
            <Heading>Pagar acordo</Heading>
            <Text>
              <Warning />
              Seu acordo está pendente de pagamento
            </Text>
          </>
        ) : (
          <>
            <Heading>Parabéns por concluir o acordo</Heading>
            <Text>Agora só falta realizar o pagamento</Text>
          </>
        )}
      </Container>
      <Outlet />
    </>
  );
}
