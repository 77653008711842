import { styled } from "../../../styles";
import { Button } from "../../Button";
import { ModalContent, ModalIconClose, ModalTitle } from "../../Modal/styles";

export const ToggleModalButton = styled(Button, {
  display: "flex",
  alignItems: "center",
  gap: 6,
});

export const ModalContainer = styled(ModalContent, {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  textAlign: "center",
  height: "auto",

  "@max_width_480": {
    padding: "24px 16px 56px 16px",
  },

  "@min_width_1201": {
    padding: "46px 24px 40px 24px",
  },
});

export const Title = styled(ModalTitle, {
  "@max_width_480": {
    marginTop: 42,
  },
});

export const Text = styled("p", {
  lineHeight: "22px",
  color: "$neutralDarkest",
});

export const Actions = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  marginTop: 16,

  [`${Button}`]: {
    "@max_width_480": {
      padding: 0,
    },
  },
});

export const CloseButton = styled(ModalIconClose, {
  top: 24,
  right: 16,
});
