import { useEffect } from "react";
import { CellPhone } from "@havan/paladinos-react-icons";
import { useAuth } from "@/auth/hook";
import { PAGE_TITLE } from "@/utils/constants";
import { QrCodeApp } from "@/assets/icons/QrCodeApp";
import phone from "@/assets/images/Havan-Negociacao-Aplicativo.webp";
import {
  NoDebtDescription,
  BlueCover,
  Container,
  Content,
  Description,
  DownloadAppContainer,
  Heading,
  HeadingDeal,
  Image,
  ImageContainer,
  Link,
  NoDebt,
  QrCodeInfo,
  TextCoverContainer,
} from "./styles";

export function SemDividas() {
  const { auth } = useAuth();

  function formatName() {
    return auth.user.nome.split(" ")[0].toLowerCase();
  }

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Sem dívidas`;
  }, []);

  return (
    <Container>
      <BlueCover>
        <Content>
          <TextCoverContainer>
            <div>
              <Heading>👋 Olá, {formatName()}</Heading>
              <NoDebt>Não há acordo disponível pra você</NoDebt>
              <NoDebtDescription>
                Caso tenha faturas em atraso, procure uma de nossas lojas
                físicas
              </NoDebtDescription>
            </div>

            <CellPhone />
            <HeadingDeal>Baixe o aplicativo e acompanhe sua fatura</HeadingDeal>
            <Description>
              Caso já tenha feito uma negociação, as suas parcelas foram
              adicionadas a fatura do seu Cartão Havan, você pode acompanhar e
              realizar o pagamento pelo Aplicativo
            </Description>

            <DownloadAppContainer>
              <QrCodeApp />
              <QrCodeInfo>
                <p>
                  Aponte a câmera do celular para o QR Code e{" "}
                  <Link to="//havan.page.link/nYJz" target="_blank">
                    Baixe o aplicativo
                  </Link>
                </p>
              </QrCodeInfo>
            </DownloadAppContainer>
          </TextCoverContainer>
        </Content>
      </BlueCover>
      <ImageContainer>
        <Image src={phone} alt="" />
      </ImageContainer>
    </Container>
  );
}
