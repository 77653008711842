import { useEffect } from "react";
import {
  CheckCircle,
  Close,
  CloseCircle,
  Info,
} from "@havan/paladinos-react-icons";
import { useAlert } from "./hook";
import { CloseButton, Container, Description, Icon, Title } from "./styles";
import { AlertProps } from "./types";

export function Alert({ id, variant, title, description }: AlertProps) {
  const alert = useAlert();

  useEffect(() => {
    const timer = setTimeout(() => alert.remove(id), 5000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Container variant={variant}>
      <Icon>
        {(variant === "alert" || variant === "info") && <Info />}
        {variant === "error" && <CloseCircle />}
        {variant === "success" && <CheckCircle />}
      </Icon>
      <div>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </div>
      <CloseButton onClick={() => alert.remove(id)}>
        <Close />
      </CloseButton>
    </Container>
  );
}
