import { useLocation } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import { ArrowLeft, Close } from "@havan/paladinos-react-icons";
import { ModalOverlay } from "../../Modal/styles";
import {
  Actions,
  CloseButton,
  ModalContainer,
  Title,
  ToggleModalButton,
} from "./styles";
import { Button } from "../../Button";
import { useAuth } from "../../../auth/hook";

export function ModalSignOut() {
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const isInPayment = pathname === "/pagamento";

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <ToggleModalButton variant="hiperlink">
          <ArrowLeft /> Sair da negociação
        </ToggleModalButton>
      </Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay>
          <ModalContainer>
            <Title>Tem certeza que deseja sair da negociação?</Title>

            {isInPayment ? (
              <p>Ao sair, o pagamento ficará pendente</p>
            ) : (
              <p>Ao sair, todo progresso da simulação será perdido</p>
            )}

            <Actions>
              <Button
                id="sair"
                variant="secondary"
                color="danger"
                onClick={logout}
              >
                Sim, desejo sair
              </Button>
              <Dialog.Close asChild>
                <Button>
                  {isInPayment
                    ? "Realizar o pagamento"
                    : "Continuar minha negociação"}
                </Button>
              </Dialog.Close>
            </Actions>
            <Dialog.Close asChild>
              <CloseButton aria-label="Close">
                <Close />
              </CloseButton>
            </Dialog.Close>
          </ModalContainer>
        </ModalOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
