import { useLocation } from "react-router-dom";
import { ArrowLeft, LogoLettering } from "@havan/paladinos-react-icons";
import { ModalSignOut } from "./ModalSignOut";
import { Container, Content, NavLink } from "./styles";

export function Header() {
  const { pathname } = useLocation();

  return (
    <Container>
      <Content>
        <LogoLettering height={24} />

        {pathname === "/negociar" ? (
          <NavLink to="/">
            <ArrowLeft /> Voltar
          </NavLink>
        ) : (
          <ModalSignOut />
        )}
      </Content>
    </Container>
  );
}
