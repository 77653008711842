import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError, HttpStatusCode } from "axios";
import { Verified } from "@havan/paladinos-react-icons";
import { Button } from "@/components/Button";
import { Step } from "@/components/Step";
import { Box } from "@/components/Box";
import { SummaryAgreement } from "../components/SummaryAgreement";
import { ButtonQueroParcelar, Container, NavigationButtons } from "./styles";
import { SimulationStep } from "@/enums/SimulationStep";
import { OpcoesParcelamento } from "@/services/renegociacao/types";
import { Loading } from "@/components/Loading";
import { useSimulation } from "@/providers/Simulation/hook";
import { Renegociacao } from "@/services/renegociacao";
import { PaymentState } from "../pagamento/types";
import { PAGE_TITLE } from "@/utils/constants";
import { useAlert } from "@/components/Alert/hook";
import { ResponseError } from "@/services/types";

interface Props {
  parcela?: OpcoesParcelamento;
}

export function BestOffer({ parcela }: Props) {
  const { bestOffer, setBestOffer, setInstallments, setSimulationStep } =
    useSimulation();
  const [isFetchingBestOffer, setIsFetchingBestOffer] = useState(false);
  const navigate = useNavigate();
  const alert = useAlert();

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Melhor oferta`;
  }, []);

  useEffect(() => {
    if (!bestOffer) {
      setIsFetchingBestOffer(true);
      Renegociacao.melhorOferta()
        .then((response) => {
          const { acordo, simulacaoRenegociacao } = response.data.result;
          if (acordo) {
            if (acordo.temPagamento) {
              return navigate("/sem-dividas");
            }

            const payment = {
              expiresAt: acordo.primeiraParcela.dataVencimento,
              total: acordo.primeiraParcela.valor,
              hasntPayed: true,
            } as PaymentState;

            return navigate("/pagamento", { state: payment });
          }

          if (simulacaoRenegociacao) {
            setBestOffer(simulacaoRenegociacao);
            setInstallments(simulacaoRenegociacao.parcelamento[0].parcelas);
          }
        })

        .catch((error: AxiosError<ResponseError>) => {
          if (error.response?.status === HttpStatusCode.BadRequest) {
            const errorMessage = error.response.data.errors[0];

            if (errorMessage) alert.alert("", errorMessage);

            navigate("/");
          }
        })
        .finally(() => setIsFetchingBestOffer(false));
    }
  }, []);

  return (
    <Container>
      {!isFetchingBestOffer && !!bestOffer ? (
        <>
          <Step title="Oferta" current={2} max={4} />
          <Box.Root>
            <Box.Header>
              <Verified height={36} width={36} />
              Melhor oferta
            </Box.Header>
            <SummaryAgreement
              parcela={bestOffer.parcelamento[0]}
              valorDivida={bestOffer.valorDivida}
            />
          </Box.Root>
          <NavigationButtons>
            <Button
              id="pagamento-a-vista"
              onClick={() => setSimulationStep(SimulationStep.PaymentInCash)}
              disabled={isFetchingBestOffer}
            >
              Quero pagar à vista
            </Button>
            <ButtonQueroParcelar
              id="pagamento-parcelado"
              variant="secondary"
              onClick={() =>
                setSimulationStep(SimulationStep.PaymentInInstallments)
              }
              disabled={isFetchingBestOffer}
            >
              Quero parcelar
            </ButtonQueroParcelar>
          </NavigationButtons>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}
