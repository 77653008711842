import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AxiosError } from "axios";
import {
  CalendarPositive,
  Close,
  Coin,
  Copy,
} from "@havan/paladinos-react-icons";
import { Pagamento } from "@/services/pagamento";
import { Pix } from "@/services/pagamento/types";
import { formatMoney } from "@/utils/formatters";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { Loading } from "@/components/Loading";
import {
  ModalContent,
  ModalIconClose,
  ModalOverlay,
  ModalTitle,
} from "@/components/Modal/styles";
import {
  Action,
  ButtonPayment,
  Instruction,
  InstructionText,
  ModalBody,
  Payment,
  PaymentInfoLabel,
  PaymentInfoLine,
  PaymentInfoValue,
} from "../styles";

export function ModalPix() {
  const [pix, setPix] = useState<Pix>();
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const alert = useAlert();

  function fetchPix() {
    setIsFetching(true);
    setIsOpen(true);
    Pagamento.pix()
      .then((response) => setPix(response.data.result))
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response?.data.errors) {
          const { errors } = error.response.data;

          errors.forEach((message) => {
            alert.alert("Pix", message);
          });
        }

        setIsOpen(false);
      })
      .finally(() => setIsFetching(false));
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <ButtonPayment id="abre-modal-pix" onClick={() => fetchPix()}>
          Pix
        </ButtonPayment>
      </Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Pagar com Pix</ModalTitle>
            <ModalBody>
              {!isFetching && !!pix && isOpen ? (
                <>
                  <Instruction>
                    <InstructionText>
                      Aproxime o celular da tela para fazer a leitura do QR Code
                    </InstructionText>
                    <img
                      src={`data:image/png;base64,${pix.qrCodeBase64}`}
                      width={275}
                      alt=""
                    />
                  </Instruction>
                  <Action
                    id="copia-codigo-pix"
                    onClick={() =>
                      navigator.clipboard.writeText(pix.copiaCola ?? "")
                    }
                  >
                    <Copy /> Copiar código Pix
                  </Action>
                  <Payment>
                    <PaymentInfoLine>
                      <PaymentInfoLabel>
                        <Coin />
                        Valor
                      </PaymentInfoLabel>
                      <PaymentInfoValue>
                        {formatMoney(pix.valor)}
                      </PaymentInfoValue>
                    </PaymentInfoLine>
                    <PaymentInfoLine>
                      <PaymentInfoLabel>
                        <CalendarPositive />
                        Vencimento
                      </PaymentInfoLabel>
                      <PaymentInfoValue>
                        {new Date(pix.dataVencimento).toLocaleDateString()}
                      </PaymentInfoValue>
                    </PaymentInfoLine>
                  </Payment>
                </>
              ) : (
                <Loading />
              )}
            </ModalBody>
            <Dialog.Close asChild>
              <ModalIconClose
                aria-label="Close"
                onClick={() => setIsOpen(false)}
              >
                <Close />
              </ModalIconClose>
            </Dialog.Close>
          </ModalContent>
        </ModalOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
