import { useEffect, useState } from "react";
import { addDays, eachDayOfInterval } from "date-fns";
import * as RadixSelect from "@radix-ui/react-select";
import { AxiosError } from "axios";
import {
  ChevronDown,
  Invoice,
  Minus,
  Plus,
} from "@havan/paladinos-react-icons";
import { Box } from "@/components/Box";
import { Input } from "@/components/Input";
import { Step } from "@/components/Step";
import { SimulationStep } from "@/enums/SimulationStep";
import { formatMoney } from "@/utils/formatters";
import { Confirm } from "../../paymentInCash/styles";
import { Container } from "../../bestOffer/styles";
import { PAGE_TITLE } from "@/utils/constants";
import { Loading } from "@/components/Loading";
import { useSimulation } from "@/providers/Simulation/hook";
import { Renegociacao } from "@/services/renegociacao";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { Button } from "@/components/Button";
import {
  ButtonIcon,
  Header,
  InputDateContainer,
  InstallmentContainer,
  Loader,
  NavigationButtons,
  Parcela,
  ParcelaContainer,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectPortal,
  SelectTrigger,
  Shortcut,
  Text,
} from "./styles";

export function PaymentInInstallments() {
  const {
    bestOffer,
    installments,
    setInstallments,
    setInstallmentOptions,
    setSimulationStep,
    installmentOptions,
  } = useSimulation();

  const parcela = installmentOptions?.parcelamento.find(
    (parcela) => parcela.parcelas === installments
  );

  const [valorEntrada, setValorEntrada] = useState(parcela?.valorEntrada ?? 0);
  const [expiresAt, setExpiresAt] = useState(new Date().toISOString());
  const [isFetchingInstallmentOptions, setIsFetchingInstallmentOptions] =
    useState(false);

  const alert = useAlert();

  const expireAtOptions = eachDayOfInterval({
    start: new Date(),
    end: addDays(new Date(), 5),
  });

  const minParcelas = installmentOptions?.parcelamento?.length
    ? Math.min(...installmentOptions?.parcelamento.map((item) => item.parcelas))
    : undefined;

  const maxParcelas = installmentOptions?.parcelamento
    ? Math.max(...installmentOptions?.parcelamento.map((item) => item.parcelas))
    : undefined;

  const medianParcela =
    minParcelas && maxParcelas && maxParcelas - minParcelas > 2
      ? Math.ceil((minParcelas + maxParcelas) / 2)
      : undefined;

  const valorMinimo = bestOffer?.valorMinimoParcela ?? 0;

  const helpTextOutOfBounds =
    valorEntrada < valorMinimo
      ? `O valor mínimo é de ${formatMoney(valorMinimo)}`
      : `O valor maximo é de ${formatMoney(bestOffer!.valorDivida)}`;

  const helpTextInputEntry =
    (!!bestOffer?.valorDivida &&
      valorEntrada <= bestOffer.valorDivida &&
      valorEntrada >= valorMinimo) ||
    valorEntrada === 0
      ? "A entrada é considerada uma parcela"
      : helpTextOutOfBounds;

  const downPaymentHelperText =
    installmentOptions?.parcelamento.length === 0
      ? "Não existem opções de parcelamento para este valor de entrada"
      : helpTextInputEntry;

  const isHelpTextEntryError =
    !!bestOffer?.valorDivida &&
    (valorEntrada < valorMinimo ||
      valorEntrada > bestOffer?.valorDivida ||
      installmentOptions?.parcelamento.length === 0) &&
    !!valorEntrada;

  const isHelpTextEntrySuccess =
    valorEntrada >= valorMinimo &&
    !!bestOffer?.valorDivida &&
    bestOffer?.valorDivida > valorEntrada;
  const showParcela = valorEntrada > 0 && !isHelpTextEntryError;
  const blockInstallmentsEdit =
    isFetchingInstallmentOptions || !isHelpTextEntrySuccess;

  const cantConfirm =
    expiresAt.length === 0 ||
    valorEntrada <= 0 ||
    isHelpTextEntryError ||
    installments <= 1 ||
    !parcela;

  const isDisabledButtonMinusInstallment =
    !minParcelas || installments <= minParcelas || blockInstallmentsEdit;

  const isDisabledButtonPlusInstallment =
    !maxParcelas || installments >= maxParcelas || blockInstallmentsEdit;

  const canFetchInstallments =
    !!bestOffer?.valorDivida &&
    valorEntrada >= valorMinimo &&
    valorEntrada <= bestOffer.valorDivida;

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Entrada do pagamento parcelado`;
  }, []);

  useEffect(() => {
    if (canFetchInstallments) {
      setIsFetchingInstallmentOptions(true);
      const timer = setTimeout(() => {
        Renegociacao.opcoesParcelamento({
          DataPrimeiraParcela: expiresAt,
          ValorEntrada: valorEntrada,
          ValorMinimoParcela: valorMinimo,
        })
          .then((response) => {
            setInstallmentOptions(response.data.result);
            setInstallments(response.data.result.parcelamento[0].parcelas);
          })
          .catch((error: AxiosError<ResponseError>) => {
            if (error.response?.data.errors) {
              error.response.data.errors.forEach((errorMessage) => {
                alert.alert("Atenção", errorMessage);
              });
            }
          })
          .finally(() => setIsFetchingInstallmentOptions(false));
      }, 1500);

      return () => {
        setIsFetchingInstallmentOptions(false);
        clearTimeout(timer);
      };
    }
  }, [valorEntrada, valorMinimo, expiresAt]);

  return (
    <Container>
      <Step
        handleStepBack={() => setSimulationStep(SimulationStep.BestOffer)}
        title="Valor de entrada"
        current={3}
        max={4}
      />
      <Box.Root>
        <Header>
          <Box.Header>
            <Invoice />
            Valor de entrada
          </Box.Header>
        </Header>
        <InputDateContainer>
          <label htmlFor="">Data de vencimento da entrada</label>
          <RadixSelect.Root onValueChange={setExpiresAt} value={expiresAt}>
            <SelectTrigger>
              <RadixSelect.Value>
                {new Date(expiresAt).toLocaleDateString()}
              </RadixSelect.Value>
              <SelectIcon asChild>
                <span>
                  <ChevronDown />
                </span>
              </SelectIcon>
            </SelectTrigger>
            <SelectPortal>
              <SelectContent position="popper" className="full" sideOffset={12}>
                <RadixSelect.Viewport>
                  {expireAtOptions.map((day) => (
                    <SelectItem
                      value={day.toISOString()}
                      key={day.toLocaleDateString()}
                    >
                      <RadixSelect.ItemText>
                        {day.toLocaleDateString()}
                      </RadixSelect.ItemText>
                      <RadixSelect.ItemIndicator />
                    </SelectItem>
                  ))}
                </RadixSelect.Viewport>
              </SelectContent>
            </SelectPortal>
          </RadixSelect.Root>
        </InputDateContainer>
        <div>
          <Input
            label="Valor de entrada"
            min={valorMinimo}
            max={bestOffer?.valorDivida}
            isError={isHelpTextEntryError}
            step="0.01"
            isSuccess={isHelpTextEntrySuccess}
            type="number"
            value={valorEntrada ? valorEntrada : ""}
            helperText={downPaymentHelperText}
            onChange={(event) => {
              setValorEntrada(Number(event.target.value));
            }}
            isMoney
          />
        </div>
        {showParcela && (
          <InstallmentContainer isLoading={isFetchingInstallmentOptions}>
            <Text>Valor restante parcelado em</Text>
            <ParcelaContainer>
              <ButtonIcon
                onClick={() => setInstallments((state) => state - 1)}
                disabled={isDisabledButtonMinusInstallment}
              >
                <Minus />
              </ButtonIcon>
              <Parcela>
                {!blockInstallmentsEdit && (
                  <span>
                    {installments - 1}x de{" "}
                    {formatMoney(parcela?.valorParcela ?? 0)}
                  </span>
                )}
              </Parcela>
              <ButtonIcon
                onClick={() => setInstallments((state) => state + 1)}
                disabled={isDisabledButtonPlusInstallment}
              >
                <Plus />
              </ButtonIcon>
            </ParcelaContainer>
            {!blockInstallmentsEdit &&
              !!installmentOptions &&
              installmentOptions.parcelamento.length > 2 && (
                <Shortcut>
                  {minParcelas && (
                    <span onClick={() => setInstallments(minParcelas)}>
                      {minParcelas - 1}x
                    </span>
                  )}
                  {medianParcela && (
                    <span onClick={() => setInstallments(medianParcela)}>
                      {medianParcela - 1}x
                    </span>
                  )}

                  {maxParcelas && (
                    <span onClick={() => setInstallments(maxParcelas)}>
                      {maxParcelas - 1}x
                    </span>
                  )}
                </Shortcut>
              )}
            <Loader>
              <Loading />
            </Loader>
          </InstallmentContainer>
        )}
      </Box.Root>
      <NavigationButtons>
        <Confirm
          onClick={() =>
            setSimulationStep(SimulationStep.PaymentInInstallmentsSummary)
          }
          disabled={cantConfirm}
        >
          Confirmar
        </Confirm>
        <Button
          variant="secondary"
          onClick={() => setSimulationStep(SimulationStep.BestOffer)}
        >
          Voltar para a melhor oferta
        </Button>
      </NavigationButtons>
    </Container>
  );
}
