import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Coin } from "@havan/paladinos-react-icons";
import { Info } from "../../../components/Info";
import { Box } from "../../../components/Box";
import { PAGE_TITLE } from "../../../utils/constants";
import { formatMoney } from "../../../utils/formatters";
import { Container } from "../bestOffer/styles";
import { ModalBoleto } from "./ModalBoleto";
import { ModalPix } from "./ModalPix";
import { Buttons, DescriptionLine, ValueDate } from "./styles";
import { PaymentState } from "./types";
import {
  LabelPayment,
  ValuePayment,
} from "../paymentInInstallment/summary/styles";

export function Pagamento() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const payment = state as PaymentState;

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Realizar pagamento`;

    if (!payment) {
      navigate("/");
    }
  }, []);

  return (
    <Container>
      <Box.Root>
        <Box.Header>Realizar pagamento</Box.Header>
        <DescriptionLine>
          <LabelPayment>
            <Coin />
            Valor da entrada
          </LabelPayment>
          <ValuePayment>{formatMoney(payment?.total)}</ValuePayment>
        </DescriptionLine>
        <DescriptionLine>
          <p>Data de vencimento</p>
          <ValueDate>
            {new Date(payment?.expiresAt).toLocaleDateString()}
          </ValueDate>
        </DescriptionLine>
        <Buttons>
          <ModalPix />
          <ModalBoleto />
        </Buttons>
        {!payment.notPaid && (
          <Info>
            Caso não realize o pagamento até a data de vencimento, a sua
            negociação será cancelada
          </Info>
        )}
      </Box.Root>
    </Container>
  );
}
