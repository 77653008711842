import { Link } from "react-router-dom";
import { styled } from "../../styles";

export const Container = styled("header", {
  backgroundColor: "$background",
  height: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Content = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100vw",
  maxWidth: 1160,
  padding: "0 $4",
  gap: 16,

  button: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  svg: {
    width: "auto",
    color: "$primaryDark",
  },

  "@max_width_480": {
    "& > svg": {
      height: 20,
    },
  },
});

export const NavLink = styled(Link, {
  display: "flex",
  alignItems: "center",
  gap: 8,
  textDecoration: "none",
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$primaryDark",
});
