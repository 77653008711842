import * as Checkbox from "@radix-ui/react-checkbox";
import { styled } from "../../styles";
import { Box } from "../Box";

export const BoxRoot = styled(Box.Root, {
  marginTop: 16,
});

export const List = styled("ol", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  lineHeight: "22px",
  color: "$neutralDarkest",
  textAlign: "start",

  "li::marker": {
    fontWeight: "$bold",
  },

  li: {
    marginLeft: 16,
  },
});

export const TermCheckContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 16,

  input: {
    height: 24,
    width: 24,
    borderRadius: 6,
  },

  "@max_width_480": {
    alignItems: "flex-start",
    textAlign: "start",
  },
});

export const CheckboxRoot = styled(Checkbox.Root, {
  all: "unset",
  backgroundColor: "$primaryLighter",
  size: 24,
  borderRadius: 6,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 1,
  boxSizing: "border-box",
  borderStyle: "solid",
  borderColor: "$primaryMedium",

  "&:hover": {
    cursor: "pointer",
  },

  '&[data-state="checked"]': {
    backgroundColor: "$primaryMedium",
  },
});

export const CheckboxIndicator = styled(Checkbox.Indicator, {
  color: "$neutralLighter",
  display: "flex",
});

export const LabelCheckbox = styled("label", {
  fontWeight: "$bold",
  lineHeight: "22px",
  color: "$neutralDarkest",
});
